// import 'babel-polyfill';
// import svg4everybody from 'svg4everybody';
import $ from 'jquery';
import validate from "jquery-validation";
// import select2 from '../../node_modules/select2/dist/js/select2.full';
import Inputmask from "inputmask";
import Swiper from 'swiper';
// import 'tooltipster/dist/js/tooltipster.main.min';
// import 'tooltipster/dist/js/tooltipster.bundle.min';
//import Swiper, { Navigation, Pagination, Thumbs, Autoplay } from 'swiper';
//Swiper.use([Navigation, Pagination, Thumbs, Autoplay]);
// select2($);
// import jQueryScrollbar from 'jquery.scrollbar';
import { Fancybox, Carousel, Panzoom } from "@fancyapps/ui";
import gsap from "gsap";
import { CountUp } from 'countup.js';


window.$ = $; 
window.jQuery = $;
window.validate = validate;
// window.select2 = select2;
// window.Inputmask = Inputmask;
window.Swiper = Swiper;
// window.gsap= gsap;
window.$W = $(window);
window.$D = $(document);
window.$H = $('html'); 
window.$B = $('body');
window.Fancybox= Fancybox; 
window.gsap= gsap;
window.CountUp= CountUp;
// svg4everybody();
// import 'ninelines-ua-parser';
